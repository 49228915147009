import { useState } from "react";
import logo from "./7.png";

export default function MainBody() {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [cardsLoaded, setCardsLoaded] = useState(false);

  let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
    navigator.userAgent
  )


  const handleImageLoad = () => {
    setTimeout(() => {
      setIsImageLoaded(true);
      setTimeout(() => {
        setCardsLoaded(true);
      }, 200);
    }, 300);
  };

  function izbaciAlert(poruka, e) {
    e.preventDefault();
    alert(poruka)
  }

  return (
    <div className="container mx-auto p-6 cursor-pointer text-center logoUp">
      <div
        className="flex justify-center animate-slide-in1"
        style={{ minHeight: "140px" }}
      >
        <img
          src={logo}
          alt="logo"
          style={{ maxHeight: "140px" }}
          onLoad={handleImageLoad}
        />
      </div>

      {isImageLoaded ? (
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-4 lg:mx-96">
          <a
            className="bg-gray-700 p-5 rounded-md shadow-md transition duration-300 transform hover:scale-105 myCard animate-slide-in1"
            target="_blank"
            href="https://instagram.com/evolutionphoto.rs"
          >
            <div className="flex justify-between flex-row items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                className="bi bi-instagram"
                viewBox="0 0 16 16"
              >
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
              </svg>
              <h2 className="font-semibold px-16 text-mojFont">Instagram</h2>
            </div>
          </a>

          <a
            className="bg-gray-700 p-5 rounded-md shadow-md transition duration-300 transform hover:scale-105 myCard animate-slide-in2"
            target="_blank"
            href="https://facebook.com/evolutionphoto.rs"
          >
            <div className="flex justify-between flex-row items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                className="bi bi-facebook"
                viewBox="0 0 16 16"
              >
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
              </svg>
              <h2 className="font-semibold px-16 text-mojFont">Facebook</h2>
            </div>
          </a>

          <a
            className="bg-gray-700 p-5 rounded-md shadow-md transition duration-300 transform hover:scale-105 myCard animate-slide-in3"
            target="_blank"
            href="https://youtube.com"
            onClick={(e) => izbaciAlert('Youtube kanal je jos uvek u izradi :)', e)}
          >
            <div className="flex justify-between flex-row items-center">
              <svg
                style={{ color: "white" }}
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                className="bi bi-youtube"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"
                  fill="white"
                ></path>
              </svg>
              <h2 className="font-semibold px-16 text-mojFont">Youtube</h2>
            </div>
          </a>

          <a
            className="bg-gray-700 p-5 rounded-md shadow-md transition duration-300 transform hover:scale-105 myCard animate-slide-in4"
            target="_blank"
            href="https://site.evolutionphoto.rs"
            // onClick={(e) => izbaciAlert('Website je jos uvek u izradi :)', e)}
          >
            <div className="flex justify-between flex-row items-center">
              <svg
                style={{ color: "white" }}
                width="40"
                height="40"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14 7C13.4477 7 13 7.44772 13 8V16C13 16.5523 13.4477 17 14 17H18C18.5523 17 19 16.5523 19 16V8C19 7.44772 18.5523 7 18 7H14ZM17 9H15V15H17V9Z"
                  fill="white"
                ></path>
                <path
                  d="M6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H10C10.5523 9 11 8.55228 11 8C11 7.44772 10.5523 7 10 7H6Z"
                  fill="white"
                ></path>
                <path
                  d="M6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13H10C10.5523 13 11 12.5523 11 12C11 11.4477 10.5523 11 10 11H6Z"
                  fill="white"
                ></path>
                <path
                  d="M5 16C5 15.4477 5.44772 15 6 15H10C10.5523 15 11 15.4477 11 16C11 16.5523 10.5523 17 10 17H6C5.44772 17 5 16.5523 5 16Z"
                  fill="white"
                ></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 3C2.34315 3 1 4.34315 1 6V18C1 19.6569 2.34315 21 4 21H20C21.6569 21 23 19.6569 23 18V6C23 4.34315 21.6569 3 20 3H4ZM20 5H4C3.44772 5 3 5.44772 3 6V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V6C21 5.44772 20.5523 5 20 5Z"
                  fill="white"
                ></path>
              </svg>
              <h2 className="font-semibold px-16 text-mojFont">Website</h2>
            </div>
          </a>
        {isMobile ? 
          <a
          className="bg-gray-700 p-5 rounded-md shadow-md transition duration-300 transform hover:scale-105 myCard animate-slide-in5"
          href="tel:+381603750661"
          >
            <div className="flex justify-between flex-row items-center">
              <svg
                style={{ color: "white" }}
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                className="bi bi-telephone"
                viewBox="0 0 16 16"
                >
                <path
                  d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                  fill="white"
                  ></path>
              </svg>
              <h2 className="font-semibold px-16 text-mojFont">Kontakt</h2>
            </div>
          </a>
            : null }
        </div>
      ) : null}

      {cardsLoaded ? (
        <footer className=" text-white py-4 text-center animate-slide-in5">
          <p>&copy; {new Date().getFullYear()} EvolutionPhoto</p>
        </footer>
      ) : null}
    </div>
  );
}
